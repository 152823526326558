import { AxiosResponse } from "axios"
import { useNavigate } from "react-router"
import { ThunkDispatch } from "redux-thunk"
import {ApiHelper, hideLoading, showLoading} from "../../../Helpers/ApiRequestHelper"
//import { storeConfig } from "../storeConfig"
import { useCookies } from "react-cookie"


export const LOGIN_START = "LOGIN_START"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"

export const LOGOUT_START = "LOGOUT_START"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_ERROR = "LOGOUT_ERROR"



interface LOGIN_START {
    type: typeof LOGIN_START,
    ///payload: {username: string, password: string, token: string}
}

interface LOGIN_SUCCESS {
    type: typeof LOGIN_SUCCESS,
    payload: {} // {username: string, password: string, captcha: string, remember : boolean}
}

interface LOGIN_ERROR {
    type: typeof LOGIN_ERROR,
    payload: {}
}

interface LOGOUT_START {
    type: typeof LOGOUT_START,
}

interface LOGOUT_SUCCESS {
    type: typeof LOGOUT_SUCCESS,
    payload: {}
}

interface LOGOUT_ERROR {
    type: typeof LOGOUT_ERROR,
}

export type AuthenticationActionTypes = LOGIN_START | LOGIN_SUCCESS | LOGIN_ERROR | LOGOUT_START | LOGOUT_SUCCESS | LOGOUT_ERROR;
export type AuthenticationDispatch = ThunkDispatch<LoginComponentState, void, AuthenticationActionTypes>

export type LoginComponentState = {
    username: string,
    password: string,
    token: string,
    isRemember: boolean,
}

export const doLogin = (loginModel: LoginComponentState) => async (dispatch: AuthenticationDispatch) => {
    showLoading();

    dispatch({ type: LOGIN_START });
    try {
        let { data } = await ApiHelper.post("/v1/Account/Login", loginModel);
        hideLoading();
        localStorage.setItem("token", data.data.token)
        //const [cookie, setCookie] = useCookies(['token']);
        //setCookie("token", data.token);
        dispatch({ type: LOGIN_SUCCESS, payload: data.data.token });

    } catch (err: any) {
        hideLoading();
        dispatch({ type: LOGIN_ERROR, payload: "Login olan zaman xəta baş verdi!" });
    }
}



export const doLogOut = () => async (dispatch: AuthenticationDispatch) => {
    //const state = storeConfig.getState();
    dispatch({ type: LOGOUT_START });
    localStorage.removeItem("token")
    dispatch({ type: LOGOUT_SUCCESS, payload: {} });
    const history = useNavigate();
    history("/login", { state: {} })
}