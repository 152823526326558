import { Component } from "react";
import { connect } from "react-redux";
import SidebarComponent from "./SidebarComponent";
import HeaderComponent from "./Header";

class LayoutComponent extends Component<any> {

    render() {
        return (
            <>
                <SidebarComponent />
                <HeaderComponent />

                <div className="content-page">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default connect()(LayoutComponent)