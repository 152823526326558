import { Axios, AxiosResponse } from "axios";
import { Component, createRef, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { ApiHelper, ApiResponse, hideLoading, ResponseDataArrayModel, showLoading } from "../../Helpers/ApiRequestHelper";
import { Constants } from "../../utils/constants";



import DataTable, { DataTableRef } from 'datatables.net-react';
import DT from 'datatables.net-dt';


export interface Row {
    fullContent: string,
    contentId: number,
    classification: {
        name: string
    }
    id: number,
}

export interface ClassificationRow {
    name: string,
    id: number,
    parentId: number
}

function HomeComponent() {
    DataTable.use(DT);

    const [rows, setRows] = useState([] as Row[]);
    const [classifications, setClassifications] = useState([] as ClassificationRow[]);
    const [classificationId, setClassificationId] = useState('0');
    const [pageSize, setPageSize] = useState<number>(Constants.pageSize);
    const [pageCount, setPageCount] = useState<number>(0)

    const memoizedClassification = useMemo(() => {
        ApiHelper.get<AxiosResponse<ClassificationRow[]>>('/v1/classification/', { params: { count: 200 } }).then((d) => {
            console.log(d)
            setClassifications(d.data.data);
        }).catch(err => {
            setClassifications([] as ClassificationRow[]);
        })
    }, []);

    useEffect(() => {
        showLoading();
        ApiHelper.get<ApiResponse<ResponseDataArrayModel>>('/v1/textclassification/GetData', { params: { count: pageSize, classificationId: classificationId } }).then((d) => {
            console.log(d.data.data.totalLength)
            setPageCount(Math.round(d.data.data.totalLength / pageSize))
            setRows(d.data.data.list as Row[]);
        }).catch(err => {
            setRows([] as Row[]);
        }).finally(() => {
            hideLoading();
        })
    }, [classificationId, pageSize]);

    const checkClassification = async (id: number) => {
        ApiHelper.get<{ classification: string, contentId: number }>('/v1/textclassification/CheckClassification/' + id).then((d) => {

            var element = (document.getElementById('cmbSuggest_' + id) as HTMLSelectElement);
            element.value = d.data.contentId.toString();

        }).catch(err => {

        })
    }

    const trainModel = async () => {
        var element = (document.getElementById('loading') as HTMLDivElement);
        element.style.display = "block";

        ApiHelper.post<any>('/v1/textclassification/TrainModel').then((d) => {
            element.style.display = "none";
        }).catch(err => {
            element.style.display = "none";
        })
    }

    const getDataSource = () => {
        var element = (document.getElementById('') as HTMLTableElement);
    }

    const validateClassification = async () => {
        showLoading();
        rows.map(async (m) => await checkClassification(m.id))

    }


    const saveValidatedContents = async (id: number) => {

        rows.filter(m => m.id == id || id == 0).map(async (m) => {
            const cid = (document.getElementById('cmbSuggest_' + m.id) as HTMLSelectElement).value;
            const { data } = await ApiHelper.put('/v1/textclassification/ChangeClassification/' + m.id + '?contentId=' + cid)
        })
    }

    const createDatatable = () => {
        
    }


    return (<>
        <div className="row">
            <div className="col-12">

                <div className="card" >
                    <div className="card-header d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Müraciətlərin təsnifatlaşdırılması</h4>
                        </div>
                        <div className="header-action">
                            <div className="row">
                                <div className="col-2">
                                    <input className="form-control" type="number" value={pageSize} onChange={(inp) => setPageSize(parseInt(inp.target.value))} />
                                </div>
                                <div className="col-6">
                                    <select className="form-control" id="cmbfilter" value={classificationId} onChange={(inp) => { setClassificationId(inp.target.value) }}>
                                        <option value="0">--Seçilməyib--</option>
                                        {
                                            classifications.map(m => {

                                                if (m.parentId == null) {
                                                    return <option key={"opt_" + m.id} value={m.id} className="font-weight-bold">{m.name}</option>
                                                } else {
                                                    return <option key={"opt_" + m.id} value={m.id} className="ml-2" style={{ paddingLeft: "20px !important" }}>{"-" + m.name}</option>
                                                }


                                            })

                                        }
                                    </select>
                                </div>
                                <div className="col-4 text-right">

                                    <button className="btn btn-success" onClick={async () => { await saveValidatedContents(0).then(() => { setClassificationId("0"); setClassificationId("184") }) }}>
                                        <i className="fa fa-save"></i> Yadda saxla
                                    </button>

                                    <button className="btn btn-warning ml-2" onClick={async () => { await validateClassification(); hideLoading(); }}>
                                        <i className="fa fa-handshake" aria-hidden="true"></i> Sorğula
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">

                            <table id="datatableSource" className="table table-striped table-primary table-bordered">
                                <thead>
                                    <tr>
                                        <th className="text-center">Kod</th>
                                        <th>Mətn</th>
                                        <th className="text-center">Təsnifat</th>
                                        <th>ML</th>
                                        <th>
                                            <button className="btn btn-sm btn-outline-info"><strong>+</strong></button>
                                        </th>
                                    </tr>

                                </thead>
                                <tbody>



                                    {
                                        rows.map(row => {
                                            return <tr key={row.id}>
                                                <td>{row.id}</td>
                                                <td>{row.fullContent}</td>
                                                <td>{row.classification.name}</td>
                                                <td>

                                                    <select className="form-control" id={"cmbSuggest_" + row.id}>
                                                        <option value="0">--Seçilməyib--</option>
                                                        {
                                                            classifications.map(m => {

                                                                if (m.parentId == null) {
                                                                    return <option key={"opts_" + m.id} value={m.id} className="font-weight-bold">{m.name}</option>
                                                                } else {
                                                                    return <option key={"opts_" + m.id} value={m.id} className="ml-2" style={{ paddingLeft: "20px" }}>{"-" + m.name}</option>
                                                                }
                                                            })
                                                        }
                                                    </select>

                                                </td>
                                                <td>

                                                    <button className="btn btn-sm btn-outline-info" onClick={async () => { await saveValidatedContents(row.id); setClassificationId("0"); setClassificationId("184") }}>
                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>

                            <div className="row">
                                <div className="col-12">
                                    {/*{*/}
                                    {/*    [...Array(pageCount)].map((p) => {*/}
                                    {/*        return <button className="btn btn-primary">{ p }</button>*/}
                                    {/*    })*/}
                                    {/*}*/}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </>)

}

export default connect()(HomeComponent)