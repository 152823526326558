import { combineReducers } from "redux";
import { AuthState } from "../../../Components/App";
import { authReducer, } from "./AuthenticationReducer";

//import { LeftMenuState } from "../../../types/LeftMenu";
//import { UserState } from "../../../types/User";
//import { LeftMenuReducer } from "./LeftMenuReducer";
//import { userReducer } from "./UserReducer";
//import { appReducer } from "./AppReducer";


//import { objectReducer } from "./ObjectReducer";
//import { ObjectComponentState } from "../../../models/DRFObjectModel";

export interface AppState {
    authUser: any   
}


const rootReducer = combineReducers<AppState>({
    authUser: authReducer
});


export default rootReducer;