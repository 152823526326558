import { Component } from "react";
import { connect } from "react-redux";
import { AppState } from "../../redux/store/reducers";
import Header from "./Header";

class LoginLayoutComponent extends Component<any> {

    constructor(props: any) {
        super(props)
    }

    render() {
        return (

            <>{this.props.children}</>

        )
    }

}


const mapStateToProps = (state: AppState) => {
    return state.authUser
}

export default connect(mapStateToProps)(LoginLayoutComponent)