
import { AppState } from "."
import { AuthenticationActionTypes } from "../actions/AuththenticationActions"
import { UserAction } from "../actions/UserActions"


//export type UserModel = {
//    username: string,
//    password: string,
//}


//export type AuthState = {
//    isAuth: false,
//    token: string,
//    userData: UserModel
//}

//const INITIAL_STATE: AppState = {
//    authUser: {
//        isAuth: false,
//        token: '',
//        userData: {} as UserModel
//    }
//}


export const authReducer = (state = {}, action: AuthenticationActionTypes | UserAction) => {
    switch (action.type) {
        case "LOGIN_START":
            return {
                ...state, loading: true, error: ""
            }
            break;
        case "LOGIN_SUCCESS":
            return {
                ...state, isAuth: true, loading: false
            }
            break;
        case "LOGIN_ERROR":
            return {
                ...state, loading: false, error: "Xəta var"
            }
            break;
        case "GET_USER_DATA_START":
            return {
                ...state, loading: true, error: ""
            }
        case "GET_USER_DATA_SUCCESS":
            return {
                ...state, userData: action.payload, loading: false
            }
        case "GET_USER_DATA_ERROR":
            return {
                ...state, loading: false, error: "Xəta var"
            }

        default:
            return { ...state };
            break;
    }
}