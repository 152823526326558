import axios, { Axios } from "axios";

export const serverUrl = "https://ml.azerp.org" //"https://localhost:7148";


export const ApiHelper = axios.create({
    baseURL: serverUrl + "/api",
    responseType: "json",
    headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        "Access-Control-Allow-Headers": 'Origin, Content-Type, X-Auth-Token',
    },
    data: {
        pageSize: 20,
        pageNumber: 1
    },
});

export const showLoading =()=>{
    var element = (document.getElementById('loading') as HTMLDivElement);
        element.style.display = "block";
}

export const hideLoading = () => {
    var element = (document.getElementById('loading') as HTMLDivElement);
    element.style.display = "none";
}

//ApiHelper.interceptors.request.use(() => {
//    var element = (document.getElementById('loading') as HTMLDivElement);
//    element.style.display = "block";
//}, () => {
//    var element = (document.getElementById('loading') as HTMLDivElement);
//    element.style.display = "none";
//})



type T = ResponseDataArrayModel & any

export interface ApiResponse<T> {
    data: T,
    statusCode: number,
    error: string
}

export interface ResponseDataArrayModel {
    totalLength: number,
    list: [],
    error: string
}