import { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { UserModel } from '../redux/store/models/UserModel';
import { AppState } from '../redux/store/reducers';
import ClassificationComponent from './Classification/ClassificationComponent';
import HomeComponent from './Home/HomeComponent';
import LoginComponent from './Login/LoginComponent';
import LayoutComponent from './Shared/Layout';
import LoginLayout from './Shared/LoginLayout';
import SignupCompoent from './Signup/Signup';

export interface AuthState {
    userData: UserModel,
    token: string,
    isAuth: boolean
}

interface Props extends AppState {

}

function App(props: Props) {

    const [auth, setAuth] = useState(false);

    useEffect(() => { setAuth(localStorage.getItem('token') != null) })

    /**
     *
     */
    //constructor(props: Props) {

    //    props.authUser.isAuth = (localStorage.getItem('token') != null && localStorage.getItem('token') != undefined);
    //    props.authUser.token = localStorage.getItem('token') ?? "";

    //    super(props);

    //    if (!props.authUser.isAuth) {
    //        const navigate = useNavigate();
    //        navigate('/login');
    //    }
    //}


   
        return <>
            {
                !auth ?
                    <LoginLayout>
                        <Routes>
                            <Route path="/" element={<LoginComponent />} />
                            <Route path="/login" element={<LoginComponent />} />
                            <Route path="/register" element={<SignupCompoent />} />
                        </Routes>
                    </LoginLayout>
                    :
                    <LayoutComponent>
                        <Routes>
                            <Route path="/" element={<HomeComponent />} />
                            <Route path="/home" element={<HomeComponent />} />
                            <Route path="/classification" element={<ClassificationComponent />} />
                        </Routes>
                    </LayoutComponent>
            }

        </>

    
}

const mapStateToProps = (state: any) => {
    return {
        authUser: state.authUser
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: {

        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
