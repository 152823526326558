import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './Components/App';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'
import SignupCompoent from './Components/Signup/Signup';
import LoginComponent from './Components/Login/LoginComponent';
import { authReducer } from './redux/store/reducers/AuthenticationReducer';
import rootReducer from './redux/store/reducers';

import { RouteProps, RouterProps } from 'react-router'



export const storeConfig =  createStore(rootReducer, applyMiddleware(thunk))

ReactDOM.render(
    <Provider store={storeConfig}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);

//    <LayoutComponent>
//        <Routes>
//            <Route path="/" element={<HomeComponent />} />
//            <Route path="/home" element={<HomeComponent />} />
//            <Route path="/objects" element={<ObjectComponent />} />
//            <Route path="/users" element={<UsersComponent />} />
//            <Route path="/menu" element={<MenuPageComponent />} />
//            <Route path="/city" element={<CityComponent />} />
//        </Routes>
//    </LayoutComponent>
//) :
//    <LoginLayoutComponent>

//        <Routes>
//            <Route path="*" element={<LoginComponent />} />
//        </Routes>

//    </LoginLayoutComponent>


export interface IPrivateRouteProps extends RouterProps {
    isAuth: boolean // is authenticate route
    redirectPath: string // redirect path if don't authenticate route
}

const PrivateRoute: React.FC<IPrivateRouteProps> = (props) => {
    return props.isAuth ? (
        <Route {...props} element={props.children} children={undefined} />
    ) : (
        <Navigate to={{ pathname: props.redirectPath }} />
    )
}

export default PrivateRoute
