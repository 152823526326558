import { Axios, AxiosResponse } from "axios";
import { Component, createRef, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { ApiHelper, ApiResponse, hideLoading, ResponseDataArrayModel, showLoading } from "../../Helpers/ApiRequestHelper";
import { Constants } from "../../utils/constants";

//export interface Row {
//    fullContent: string,
//    contentId: number,
//    classification: {
//        name: string
//    }
//    id: number,
//}

export interface ClassificationRow {
    name: string,
    id: number,
    parentId: number,
    parent: ClassificationRow
    children: ClassificationRow[]
}

function ClassificationComponent() {

    const [classifications, setClassifications] = useState([] as ClassificationRow[]);
    const [pageSize, setPageSize] = useState<number>(Constants.pageSize);
    const [pageCount, setPageCount] = useState<number>(0)

    const memoizedClassification = useEffect(() => {
        ApiHelper.get<AxiosResponse<ClassificationRow[]>>('/v1/classification/', { params: { count: pageSize } }).then((d) => {

            setClassifications(d.data.data);
        }).catch(err => {
            setClassifications([] as ClassificationRow[]);
        })
    }, [pageSize]);

    return (<>
        <div className="row">
            <div className="col-12">

                <div className="card" >
                    <div className="card-header d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Müraciətlərin təsnifatları</h4>
                        </div>
                        <div className="header-action">
                            <div className="row">
                                <div className="col-12">
                                    <input className="form-control" type="number" value={pageSize} onChange={(inp) => setPageSize(parseInt(inp.target.value))} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">

                            <table id="datatableSource" className="table table-striped table-primary table-bordered">
                                <thead>
                                    <tr>
                                        <th className="text-center">Kod</th>
                                        <th>Ad</th>
                                        <th className="text-center">Üst</th>
                                        <th>
                                            <button className="btn btn-sm btn-outline-info"><strong>+</strong></button>
                                        </th>
                                    </tr>

                                </thead>
                                <tbody>


                                    {
                                        classifications.map(row => {
                                            return <>
                                                <tr key={row.id} className="font-weight-bold">
                                                    <td>{row.id}</td>
                                                    <td>{row.name}</td>
                                                    <td>{row.parentId}</td>

                                                    <td>

                                                        <button className="btn btn-sm btn-outline-info" onClick={async () => { }}>
                                                            <i className="fa fa-check" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                {
                                                    
                                                    row.children.map(cr => {
                                                        console.log(row)
                                                        return <tr key={cr.id}>
                                                            <td>{cr.id}</td>
                                                            <td>   -  {cr.name}</td>
                                                            <td>{row.name}</td>

                                                            <td>

                                                                <button className="btn btn-sm btn-outline-info" onClick={async () => { }}>
                                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </>

                                        })
                                    }
                                </tbody>
                            </table>

                            <div className="row">
                                <div className="col-12">
                                    {/*{*/}
                                    {/*    [...Array(pageCount)].map((p) => {*/}
                                    {/*        return <button className="btn btn-primary">{ p }</button>*/}
                                    {/*    })*/}
                                    {/*}*/}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </>)

}

export default connect()(ClassificationComponent)